import { Photo } from '../../../photo'
import a from './blood rush.png'
import b from './softness.png'

const photos: Photo[] = [
  {
    src: a,
    width: 2527,
    height: 1255
  },
  {
    src: b,
    width: 2527,
    height: 1255
  },
  
];

export default photos