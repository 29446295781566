import { Photo } from '../../../photo'
import a from './ghost voices I.png'
import b from './ghost voices II.png'

const photos: Photo[] = [
  {
    src: a,
    width: 16,
    height: 9
  },
  {
    src: b,
    width: 16,
    height: 9
  },
  
];

export default photos