import { Photo } from '../../../photo'
import a from './a.png'
import b from './b.png'

const photos: Photo[] = [
  {
    src: b,
    width: 2116,
    height: 1097
  },
  {
    src:  a,
    width: 2113,
    height: 1094
  },
];

export default photos